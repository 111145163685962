import React, {useState} from 'react'
import ReactWOW from 'react-wow'
import {logFormSubmission} from "../../../utils/google-tag-manager";

const ComplaintsUkForm = props => {
    const {
        isPreview,
    } = props
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobilephone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = async (evt) => {
        if (isPreview) {
            setSubmitted(true);
            return;
        }

        evt.preventDefault();
        const data = {
            fields: [
                {
                    name: "firstname",
                    value: name,
                },
                {
                    name: "email",
                    value: email,
                },
                {
                    name: "mobilephone",
                    value: mobilephone,
                },
                {
                    name: "message",
                    value: message,
                },
            ],
            context: {
            },
        };

        const response = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/25354619/20c3cb0f-55e9-4541-a906-912f1649e4fa', {
            body: JSON.stringify(data),
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            setSubmitted(true)
            logFormSubmission('complaints-uk');
        }
    }

    return (
        <section className="complaince-area">
            <div className="container">
                <div className="">
                    <div className="text-center text-lg-start ps-0">
                        <ReactWOW delay='.1s' animation='fadeInLeft'>
                            <h1 className='display-6 pb-lg-5 px-4 px-lg-0'>How does our complaints procedure work?</h1>
                        </ReactWOW>
                    </div>
                </div> 

                <div className='row'>
                    <div className="col-12 col-lg-7 pe-lg-5">
                        <div className="about-content complaints-content">
                            <div className="content">
                                <p>
                                We strive to provide you with the highest quality products and services. However, it may happen that you are not satisfied with our way of working. In case you feel the need to lodge a complaint, please contact us here                                 </p>
                                <h1 className='display-6 pt-3'>Processing your complaint</h1>
                                <div className="content-list">
                                    <div className='slider_list_text'>
                                    We will look at your message within two working days. We will handle your complaint within two weeks of receiving the message.
                                    </div>
                                    <div className='slider_list_text'>
                                    Your complaint will be processed in accordance with the SME finance regulations.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-5 getintouch-form pt-4 pt-lg-0">

                    {submitted ? <h4 className='px-5 success-message-form'>Thank you for submitting your complaint. We will process this as soon as possible.</h4> :
                    
                        <form onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-12 mx-auto">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="your_name"
                                            placeholder="Name *"
                                            value={name}
                                            required
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mx-auto">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="your_email"
                                            placeholder="Email *"
                                            value={email}
                                            required
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mx-auto">
                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            minLength={7}
                                            maxLength={20}
                                            className="form-control"
                                            id="your_number"
                                            placeholder="Phone number"
                                            value={mobilephone}
                                            onChange={e => setPhone(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-12 mx-auto">
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            id="your_message"
                                            placeholder="Message"
                                            rows="9"
                                            value={message}
                                            onChange={e => setMessage(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="text-lg-start text-center pt-1 pb-4 mx-auto">
                                <button type="submit" className="default-btn-black font-medium">
                                    SUBMIT
                                    <span></span>
                                </button>
                            </div>
                        </form>
                        }

                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default ComplaintsUkForm