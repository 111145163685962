import React from "react";
import Layout from "../../components/global/Layout";
import Above from "../../components/global/GenericBanner";
import Complaints from "../../components/global/forms/ComplaintsUk";
import {graphql} from "gatsby";
import ComplaintsUkForm from "../../components/global/forms/ComplaintsUk";

const Home = (props) => {
    const complaintsData = props.data?.allMarkdownRemark?.nodes
        ?.map(node => node.frontmatter)
        .find(x => x.locale === 'uk-en');
  return (
    <Layout seoData={complaintsData.seo}>
      <Above whiteTitle="Any problem?" greenTitle="Complaints procedure" />
      <ComplaintsUkForm />
    </Layout>
  );
};

export default Home;

export const query = graphql`
    query {
        allMarkdownRemark(
            filter: {frontmatter: {templateKey: {eq: "allComplaintsListUk"}}}
        ) {
            nodes {
                frontmatter {
                    seo {
                        title
                        description
                    }
                    locale
                }
            }
        }
    }
`;
